<template>
  <v-container>
    <navbar title="شیوه پرداخت" route = '/send_time'/>
    <v-main class="pt-15 d-flex flex-column justify-center" style="padding-bottom: 90px">
      <error-alert/>
       <costs/>
       <loading-page v-if="loading"/>
    </v-main>
    <v-btn @click="payment" block class="fixed-btn button primaryActions onPrimaryActions--text py-6">پرداخت و اتمام فرایند خرید</v-btn>
  </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import costs from './components/payment/paymentCostsCard'
import { viewbasket, submit_pay } from './models/Basket'
import LoadingPage from '@/components/tools/loadingPage.vue'
import { mapState } from 'vuex'
import store from '@/store'
import ErrorAlert from '@/components/alerts/error.alert.vue'
export default {
    components: {
        navbar,
        costs,
        LoadingPage,
        ErrorAlert,
    },
    created () {
      viewbasket()
    },
    computed: {
      ...mapState({
        loading: state => state.loading_page,
        purses: state => state.basket.purses,
        coupons: state => state.basket.coupons,
        way_pay: state => state.basket.way_pay,
        payment_tah: state => state.basket.cost_payment
      })
    },
    methods: {
      payment () {
        let coupon = ''
        let purses = []
        if (this.coupons) {
         coupon = this.coupons.id 
        }
      if (this.purses.length >= 1) {
        for (const purse of this.purses) {
          if (purse.use == true) {
            purses.push(purse.id)
          }
        }
      }
      if (this.payment_tah != 0) {
        if (!this.way_pay) {
          store.dispatch('alertError', {show: true, type: 'way_pay'})
          setTimeout(() => {
            store.dispatch('alertError', {show: false, type: 'way_pay'})
          }, 2500);
          return
        }
      }
      const payment = {
        coupons: coupon,
        purses: purses,
        pay_method: this.way_pay
      }
      submit_pay(payment)
    }
    }
}
</script>