<template>
<div>
   <v-radio-group @change="way_payment" v-model="radioGroup">
    <v-card class="pa-2 d-flex align-center card flat-card" color="surface" height="50">
        <v-row class="d-flex align-center justify-space-between">
        <v-col cols="8" class="pa-2">
         <v-radio :disabled="has_inplase != 1" value="1" label="پرداخت آنلاین"></v-radio>
        </v-col>
        <v-col class="pa-2">
          <v-img class="mr-auto ml-2" width="30" src="@/assets/icon/credit-card3.svg"></v-img>
        </v-col>

        </v-row>
    </v-card>
    <v-card class="pa-2 mt-2 card d-flex align-center flat-card" color="surface" height="50">
        <v-row class="d-flex align-center justify-space-between">

        <v-col cols="8" class="pa-2">
         <v-radio :disabled="has_inplase != 1" value="2" label="پرداخت درب منزل"></v-radio>
        </v-col>
        <v-col class="pa-2">
          <v-img class="mr-auto ml-1" width="30" src="@/assets/icon/pos-terminal.svg"></v-img>
        </v-col>

        </v-row>
    </v-card>
   </v-radio-group>
</div>
</template>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin-top: 0;
}
</style>
<script>
import store from '@/store'
  export default {
    data: () => ({
        radioGroup: 1,
        has_online: 1,
        has_inplase: 1
    }),
    created () {
      const config = JSON.parse(localStorage.getItem('config'))
      this.has_online = config.has_online_payment
      this.has_inplase = config.has_inplace_payment
    },
    methods: {
      way_payment () {
        store.dispatch('way_pay', this.radioGroup)
      }
    }
  }
</script>