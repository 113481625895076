<template>
 <div class="text-center">
   <div v-if="!loading">
     <v-expansion-panels flat class="card">
      <v-expansion-panel class="card">
        <v-expansion-panel-header >
          <span class="font">مبلغ قابل پرداخت:</span>
          <div class="mr-auto">
          <span class="font primaryText--text" v-text="payment"></span>
          </div>
          <template v-slot:actions>
          <v-icon color="primary">
            $expand
          </v-icon>
        </template>
        </v-expansion-panel-header>
        
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="5" class="pa-2 text-start">
            <span class="medium-font">جمع کل کالاها:</span>
            </v-col>
            <v-col class="pa-2 text-start">
            <span class="medium-font mr-6" v-text="total_price.gross_price_format"></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="pa-2 text-start">
            <span class="medium-font">هزینه ارسال:</span>
            </v-col>
            <v-col class="pa-2 text-start">
            <span class="medium-font mr-9" v-text="total_price.post_price_format"></span>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>
  <v-card class="card">
    <discount/>
  </v-card>
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>
  <v-card class="pa-1 mt-1 accent primaryText--text card text-center mx-auto" width="160">
    <div>
    <span class="medium-font" v-text="after_coupon"></span>
    </div>
  </v-card>
  <div>
  <div class="text-center my-2">
    <div v-if="purses.length > 0" >
   <div class="text-center my-2">
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>
  <v-card class="background card">
    <wallet/>
  </v-card>

  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>

  <v-card class="pa-1 mt-1 accent primaryText--text card text-center mx-auto" width="160">
    <div>
    <span class="medium-font" v-text="after_purse"></span>
    </div>
  </v-card>
 </div>
 </div>
 <div v-if="payment_tah != 0">
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>

  <v-card class="background card">
    <payment/>
  </v-card>
  </div>
 </div>
 </div>
 </div>
 <loading-page v-if="loading"/>
 </div>
</template>
<script>
import payment from './online-cache'
import wallet from './wallets'
import discount from './discountField'
import { mapState } from 'vuex'
import { changeTypePrice_factor } from '@/models/changeType'
import LoadingPage from '@/components/tools/loadingPage.vue'
export default {
  components: {
    payment,
    wallet,
    discount,
    LoadingPage
  },
  data: () => ({
    total: '',
    after_purse_payment: ''
  }),
  computed: {
    ...mapState({
      total_price: state => state.basket.payment_defult,
      after_coupon: state => state.basket.payment_after_coupon,
      after_purse: state => state.basket.payment_after_purse,
      purses: state => state.basket.purses,
      payment_tah: state => state.basket.cost_payment
    }),
    loading () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      if (!with_post) {
        return true
      } else {
        return false
      }
    },
    payment () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      const total= changeTypePrice_factor({
          price: with_post,
          Tax_price: this.total_price.tax,
      })
      return total
    }
  }
}
</script>